<template>
  <div class="limiter">
    <div class="container-login">
      <span
        class="title text-center"
        style="font-size: 120px !important; line-height: 140px !important"
      >
        Delegates present in the Congress
        <br />
        {{ count }} Delegates
      </span>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.getDelegates();
    this.timer = setInterval(this.getDelegates, 30000);
  },
  data() {
    return {
      valid: true,
      loading: false,
      count: 754,
    };
  },
  methods: {
    getDelegates() {
      let f = {
        events_id: this.$route.params.id,
      };
      this.api("get-delegates", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.count = response.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          self.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "Present",
};
</script>
<style>
.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f2f2f2;
}
.title {
  display: block;
  font-size: 120px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}
</style>
